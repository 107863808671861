<template>
  <div style="margin-top: 120px;">
    <search-nav :active="0"></search-nav>
    <!-- <el-affix :offset="120"> -->
      <el-row>
        <div class="container">
          <!--输入框-->
          <el-row style="margin-bottom: 30px">
            <el-col :span="13">
              <div>
                <el-autocomplete
                  style="width: 100%;"
                  clearable
                  class="inline-input"
                  v-model="conditions.keyword"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入企业名称关键词"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                  show-arrow="false"
                  size="medium"
                  input-style="border-bottom-right-radius:0px;border-top-right-radius:0px;height:50px;line-height:50px;"
                >
                </el-autocomplete>
              </div>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" style="background: #FC7A1E;border-color: #FC7A1E;" class="search-btn-search"  size="medium" @click="queryKeywordClick">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </el-row>
    <!-- </el-affix> -->
    <el-row>
      <div class="container">
        <!--筛选条件-->
        <div class="condition-content" >
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">筛选条件</span>
            <link-to-advanced></link-to-advanced>
          </div>
          <div style="padding: 10px 0 20px 0;">
            <el-row v-if="tags.length > 0" class="dis-cen-cen">
              <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #5D6FE9;">已选条件：</div></el-col>
              <el-col :span="20">
                <div class="grid-content bg-purple dis-sta-cen">
                  <el-tag style="margin-right: 10px" v-for="(tag, index) in tags" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                    {{tag.name}}
                  </el-tag>
                </div>
              </el-col>
              <el-col :span="2">
                <div @click="closeAllTag" class="grid-content bg-purple font-choose-text">清除全部</div>
              </el-col>
            </el-row>
            <el-row v-if="!conditions.provinceModel.name" class="dis-cen-sta">
              <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;" >省份地区：</div></el-col>
              <el-col :span="22">
                <div class="grid-content bg-purple">
                  <arrangement-radio :valueArr="arrangementRadioProvinceValueArr" :defaultShowNum="arrangementRadioProvinceDefaultShowNum" @handleChange="arrangementRadioProvinceChange"></arrangement-radio>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="!conditions.cityModel.name && conditions.provinceModel.name && conditions.provinceModel.name != '北京市' && conditions.provinceModel.name != '天津市' && conditions.provinceModel.name != '上海市' && conditions.provinceModel.name != '重庆市'" class="dis-cen-sta">
              <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;">城市：</div></el-col>
              <el-col :span="22">
                <div class="grid-content bg-purple">
                  <arrangement-radio :valueArr="arrangementRadioCityValueArr" :defaultShowNum="arrangementRadioCityDefaultShowNum" @handleChange="arrangementRadioCityChange"></arrangement-radio>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="!conditions.moneyModel.key" class="dis-cen-sta">
              <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;">注册资金：</div></el-col>
              <el-col :span="22">
                <div class="grid-content bg-purple">
                  <registered-capital @handleChange="registeredCapitalChange"></registered-capital>
                </div>
              </el-col>
            </el-row>
            <el-row class="dis-cen-cen" style="margin-top: 10px;">
              <el-col :span="2">
                <div class="grid-content bg-purple" >
                  <el-button type="primary" size="medium" @click="queryClick">查询</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!--内容卡片 柏成大佬-->
        <el-row style="margin-top: 30px;margin-bottom: 30px">
          <el-col :span="24">
            <div class="condition-content">
              <div class="dis-bet-cen condition-title">
                <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{ page.total &lt; 1900000 && page.total &gt; 1400000 ? 1954381 : page.total }}</span> 条符合条件的企业</span>
                <span class="advanced-query-title">
                <el-select v-model="optionsValue" placeholder="默认排序">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </span>
              </div>
              <div class="condition-content-list">
                <!-- 企业 -->
                <block  v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding list-hover">
                    <el-col :span="24">
                      <div class="condition-content-list-name dis-sta-cen">
                        <span style="cursor:pointer;" @click="companyNameClick(item, index)" v-html="item.companyName || '-'"></span>
                        <span v-if="item.registerStatStr === '存续' || item.registerStatStr === '在业'||item.registerStatStr === '在营' || item.registerStatStr === '在营（开业）企业'||item.registerStatStr === '存续（在营、开业、在册）'||item.registerStatStr === '开业'||item.registerStatStr === '正常'||item.registerStatStr === ' 迁入'||item.registerStatStr === '迁出'" class="tag-green">{{item.registerStatStr }}</span>
                        <span v-else-if="item.registerStatStr === '注销'||item.registerStatStr === '吊销，已注销'||item.registerStatStr === '吊销，未注销'||item.registerStatStr === '吊销企业'||item.registerStatStr === '已吊销'||item.registerStatStr === '异常'||item.registerStatStr === '注销企业'" class="tag-red">{{item.registerStatStr }}</span>
                        <span v-else-if="item.registerStatStr == null || item.registerStatStr == 'null' || !item.registerStatStr" class="tag-grey">暂无</span>
                        <span v-else class="tag-green">{{item.registerStatStr }}</span>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="5">法定代表人：<span class="font-color-gray">{{item.corporation || '-'}}</span></el-col>
                        <el-col :span="5">成立时间：<span class="font-color-gray">{{item.regDateStr || '-'}}</span></el-col>
                        <el-col :span="5">注册资本：<span class="font-color-gray">{{item.regMoney || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="20">电话：<span class="font-color-gray">{{item.userMobile || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="20">网站：<a class="font-color-a-blue" :href="item.website" target="_blank">{{item.website || '-'}}</a></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="20">邮箱：<span class="font-color-gray">{{item.userEmail || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row :gutter="20" class="condition-content-list-title">
                        <el-col :span="20">地址：<span class="font-color-gray">{{item.regAddress || '-'}}</span></el-col>
                      </el-row>
                    </el-col>
                    <div style="cursor:pointer;" @click="focusClick(item, index)" :class="item.foucsType === 2 ? 'focus-enterprises-tag' : 'focus-enterprises-tag-gray'"><span class="icon iconfont" style="font-size: 12px;margin-right: 5px;">{{item.foucsType === 2 ? '&#xe601;' : '&#xe615;'}}</span>{{item.foucsType === 2 ? '已关注' : '关注' }}</div>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                 <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--分页组件-->
        <div class="dis-end-sta" style="margin-bottom: 30px">
          <el-pagination
            :hide-on-single-page="true"
            :pager-count="page.pagerCount"
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="page.total">
          </el-pagination>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import ArrangementRadio from '@/components/query-conditions/arrangement-radio' // 省市的平铺单选
import RegisteredCapital from '@/components/query-conditions/registered-capital' // 注册资金
import SearchNav from '@/components/search-nav/search-nav'
import LinkToAdvanced from '@/components/link-to-advanced/link-to-advanced'
import provinceData from '@/assets/pca-code.js'
import { mapGetters } from 'vuex'
import { forwardBG } from '@/api/forward'
import {
  getFoucsByUserIdAndCompanyIds,
  InsertUserFoucs,
  DelByUserIdAndCompanyId
} from '@/api/general-query/company'
import { parseTime } from '@/utils/index' // 工具类
import { ElMessage } from 'element-plus'
export default {
  name: 'company',
  components: {
    ArrangementRadio,
    RegisteredCapital,
    SearchNav,
    LinkToAdvanced
  },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      provinceData: provinceData,
      oldKeyword: '',
      conditions: {
        keyword: this.$route.query.searchVal ? this.$route.query.searchVal : '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        moneyModel: {}, // 已经选择的注册资金
        order: '' // 排序规则
      },
      queryConditions: {
        keyword: '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        moneyModel: {}, // 已经选择的注册资金
        order: '' // 排序规则
      }, // 实际查询使用条件
      arrangementRadioProvinceValueArr: [], // 平铺单选的组件数据 省
      arrangementRadioProvinceDefaultShowNum: 12, // 平铺单选的默认显示数量 省
      arrangementRadioCityValueArr: [], // 平铺单选的组件数据 市
      arrangementRadioCityDefaultShowNum: 10, // 平铺单选的默认显示数量 市
      tags: [],
      // 数据
      dataList: [],
      // 排序的筛选
      optionsValue: '1',
      options: [{
        value: '1',
        label: '默认排序'
      }, {
        value: '2',
        label: '成立时间升序'
      }, {
        value: '3',
        label: '成立时间降序'
      }, {
        value: '4',
        label: '注册资金升序'
      }, {
        value: '5',
        label: '注册资金降序'
      }],
      page: { // 分页使用
        total: 100, // 总条数
        pagerCount: 7,
        pageNum: 1, // 当前页
        pageSize: 10 // 每页条数
      }
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  watch: {
    optionsValue () {
      const vm = this
      // 去查询
      vm.getList()
    }
  },
  mounted () {
    const vm = this
    vm.initArrangementRadioValueArr()
    vm.queryKeywordClick()
  },
  methods: {
    // 输入框输入内容触发
    querySearch (queryString, callback) {
      console.log(queryString)
      const thisSearchVal = queryString.trim()
      if (thisSearchVal) {
        const data = {
          data: { companyInfo: [{ companyName: thisSearchVal }] },
          dateBackgroundRequestType: 'post',
          dateBackgroundUrl: '/esComplexInfo/getESCompanyInfoListByCompanyKeyword'
        }
        forwardBG(data).then(result => {
          if (result.code === 0) {
            const thisSearchList = []
            const data = result.data
            if (data.length > 0) {
              for (let i = 0; i < 5; i++) {
                if (data[i]) {
                  const obj = {
                    value: data[i].companyName,
                    id: data[i].companyId
                  }
                  thisSearchList.push(obj)
                }
              }
            } else {
              const data = {
                data: {
                  keyword: thisSearchVal
                },
                dateBackgroundRequestType: 'get',
                dateBackgroundUrl: '/esComplexInfo/getCompanyByKeyword'
              }
              forwardBG(data).then(result => {
                if (result.code === 0) {
                  const thisSearchList = []
                  const data = result.data
                  for (let i = 0; i < 5; i++) {
                    if (data[i]) {
                      const obj = {
                        value: data[i].companyName
                      }
                      thisSearchList.push(obj)
                    }
                  }
                }
              }).catch(error => {
                console.log(error)
              })
            }
            if (thisSearchList.length > 0) {
              callback(thisSearchList)
            }
          } else {
            return ElMessage.error(result.msg)
          }
        }).catch(function () {
          const arr = []
          callback(arr)
        })
      } else {
        const arr = []
        callback(arr)
      }
    },
    // 输入框选择之后返回的数据
    handleSelect (item) {
      console.log(item)
    },
    initArrangementRadioValueArr () {
      const vm = this
      // for (let i = 0; i < 36; i++) {
      //   vm.arrangementRadioProvinceValueArr.push({
      //     key: '吉林' + i, // 显示的值
      //     value: i.toString(), // 实际的值
      //     tag: i.toString() // 为了带其他东西冗余
      //   })
      // }
      vm.arrangementRadioProvinceValueArr = vm.provinceData.pcaData
      // for (let i = 0; i < 5; i++) {
      //   vm.arrangementRadioCityValueArr.push({
      //     key: '长春' + i, // 显示的值
      //     value: i.toString(), // 实际的值
      //     tag: i.toString() // 为了带其他东西冗余
      //   })
      // }
    },
    // 省市组件选择完了的回调 省
    arrangementRadioProvinceChange (item) {
      const vm = this
      // console.log('回调: ' + JSON.stringify(item))
      vm.arrangementRadioCityValueArr = item.children
      vm.conditions.provinceModel = {
        code: item.code,
        name: item.name
      }
      vm.tags.push({
        name: '省份地区：' + item.name,
        value: 'provinceModel'
      })
    },
    // 省市组件选择完了的回调 市
    arrangementRadioCityChange (item) {
      const vm = this
      // console.log('回调: ' + JSON.stringify(item))
      vm.conditions.cityModel = {
        code: item.code,
        name: item.name
      }
      vm.tags.push({
        name: '城市：' + item.name,
        value: 'cityModel'
      })
    },
    // 注册资金组件选择完了的回调
    registeredCapitalChange (item) {
      const vm = this
      console.log('回调: ' + JSON.stringify(item))
      vm.conditions.moneyModel = item
      vm.tags.push({
        name: '中标金额：' + item.key,
        value: 'moneyModel'
      })
    },
    // 关闭Tag触发
    closeTag (item, index) {
      const vm = this
      vm.tags.splice(index, 1)
      vm.conditions[item.value] = {}
      if (item.value === 'provinceModel') {
        vm.conditions.cityModel = {}
        for (let i = 0; i < vm.tags.length; i++) {
          if (vm.tags[i].value === 'cityModel') {
            return vm.closeTag(vm.tags[i], i)
          }
        }
      }
    },
    // 清除全部Tag
    closeAllTag () {
      const vm = this
      vm.tags = []
      vm.conditions.provinceModel = {}
      vm.conditions.cityModel = {}
      vm.conditions.moneyModel = {}
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      console.log('当前页: ' + cuttentPage)
      vm.page.pageNum = cuttentPage
      document.documentElement.scrollTop = 0
      vm.getList()
    },
    // 关键字查询
    queryKeywordClick () {
      const vm = this
      // 如果跟上次查询的不一样就清理下面
      if (vm.oldKeyword !== vm.conditions.keyword) {
        vm.closeAllTag()
        vm.queryConditions.provinceModel = {}
        vm.queryConditions.cityModel = {}
        vm.queryConditions.moneyModel = {}
      }
      vm.oldKeyword = vm.conditions.keyword
      vm.queryConditions.keyword = vm.conditions.keyword
      vm.page.pageNum = 1
      vm.page.total = 0
      vm.dataList = []
      // 去查询
      vm.getList()
    },
    // 查询
    queryClick () {
      const vm = this
      // 获取省
      vm.queryConditions.provinceModel = JSON.parse(JSON.stringify(vm.conditions.provinceModel))
      // 获取市
      vm.queryConditions.cityModel = JSON.parse(JSON.stringify(vm.conditions.cityModel))
      // 获取注册资金
      vm.queryConditions.moneyModel = JSON.parse(JSON.stringify(vm.conditions.moneyModel))
      vm.page.pageNum = 1
      vm.page.total = 0
      vm.dataList = []
      console.log(JSON.stringify(vm.queryConditions))
      // 去查询
      vm.getList()
    },
    getList () {
      const vm = this
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let orderField = null
      let orderType = null
      // 处理排序
      if (vm.optionsValue === '1') {
        orderField = null
        orderType = null
      } else if (vm.optionsValue === '2') {
        orderField = 'reg_date'
        orderType = 'asc'
      } else if (vm.optionsValue === '3') {
        orderField = 'reg_date'
        orderType = 'desc'
      } else if (vm.optionsValue === '4') {
        orderField = 'reg_money_num'
        orderType = 'asc'
      } else if (vm.optionsValue === '5') {
        orderField = 'reg_money_num'
        orderType = 'desc'
      }
      const data = {
        dateBackgroundUrl: '/esComplexInfo/getESCompanyInfoListByModel',
        dateBackgroundRequestType: 'post',
        data: {
          companyInfo: [
            {
              companyName: vm.queryConditions.keyword ? vm.queryConditions.keyword : null,
              province: vm.queryConditions.provinceModel.name ? vm.queryConditions.provinceModel.name : null,
              city: vm.queryConditions.cityModel.name ? vm.queryConditions.cityModel.name : null,
              regMoneyStart: vm.queryConditions.moneyModel.startValue ? vm.queryConditions.moneyModel.startValue : null,
              regMoneyEnd: vm.queryConditions.moneyModel.endValue ? vm.queryConditions.moneyModel.endValue : null
            }
          ],
          pageNum: vm.page.pageNum,
          pageSize: vm.page.pageSize,
          orderField: orderField,
          orderType: orderType
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          const thisDataList = []
          const companyIds = []
          for (let i = 0; i < data.length; i++) {
            if (i === 0) {
              vm.page.total = data[i].dataTotal
            }
            if (data[i].registerStat) {
              data[i].registerStatStr = data[i].registerStat.substring(0, 2)
            }
            if (data[i].regDate) {
              data[i].regDateStr = parseTime(data[i].regDate, '{y}-{m}-{d}')
            }
            data[i].foucsType = 1 // 1未关注 2已关注
            thisDataList.push(data)
            companyIds.push(data[i].companyId)
          }
          vm.dataList = data
          // 去查询ES中没有的字段
          if (companyIds.length > 0) {
            vm.getListOtherData(companyIds)
            if (vm.idToken && vm.userInfo) {
              // 查询是否关注
              vm.getListFocus(companyIds)
            }
          }
          loading.close()
        } else {
          loading.close()
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getListOtherData (companyIds) {
      const vm = this
      const data = {
        dateBackgroundUrl: '/company/getCompanyByIds',
        dateBackgroundRequestType: 'get',
        data: {
          ids: companyIds.toString()
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let j = 0; j < vm.dataList.length; j++) {
            for (let i = 0; i < data.length; i++) {
              if (vm.dataList[j].companyId === data[i].id) {
                vm.dataList[j].userMobile = data[i].userMobile
                vm.dataList[j].userEmail = data[i].userEmail
                vm.dataList[j].website = data[i].website
              }
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 查询是否关注
    getListFocus (companyIds) {
      const vm = this
      const userInfoObj = JSON.parse(vm.userInfo)
      const data = {
        userId: userInfoObj.userId,
        companyIds: companyIds.toString()
      }
      getFoucsByUserIdAndCompanyIds(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let j = 0; j < vm.dataList.length; j++) {
            for (let i = 0; i < data.length; i++) {
              if (vm.dataList[j].companyId === data[i]) {
                vm.dataList[j].foucsType = 2
              }
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 点击了关注
    focusClick (item, index) {
      const vm = this
      if (vm.idToken && vm.userInfo) {
        const userInfoObj = JSON.parse(vm.userInfo)
        const data = {
          userId: userInfoObj.userId,
          companyId: item.companyId
        }
        if (item.foucsType === 2) { // 已经关注了 去取消
          DelByUserIdAndCompanyId(data).then(result => {
            if (result.code === 0) {
              vm.dataList[index].foucsType = 1
            } else {
              return ElMessage.error(result.msg)
            }
          }).catch(error => {
            console.log(error)
          })
        } else if (item.foucsType === 1) { // 没关注 去关注
          InsertUserFoucs(data).then(result => {
            if (result.code === 0) {
              vm.dataList[index].foucsType = 2
            } else {
              return ElMessage.error(result.msg)
            }
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        console.log('没登录去登录')
        vm.loginEmit()
      }
    },
    // 点击了 公司名 跳转到公司详情
    companyNameClick (item, index) {
      const vm = this
      console.log(JSON.stringify(item) + ' --- ' + index)
      vm.$store.dispatch('setBusId', item.companyId)
      vm.$router.push({ name: 'enterprise-details' })
    }
  }
}
</script>

<style scoped="scoped">
.already-choose{
}
.input-search {
  margin-top: 120px;
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #5D6FE9;
  border: none;
  color: #fff;
  width: 70px;
}
.conditions-font-title {
  font-size: 14px;
  color: #999;
  display: block;
  height: 40px;
  line-height: 40px;
}
.focus-enterprises-tag {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #5D6FE9;
  color: #5D6FE9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-gray {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #E6E6E6;
  color: #666;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-yellow {
  color: #EA9920;
  padding: 0 5px;
  border: 1px solid #EA9920;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}
.list-hover:hover{
  background-color: #F3F9FD;
}
.el-divider--horizontal {
  margin: 0;
}
/deep/ .el-input.el-input--medium.el-input--suffix {
  line-height: 50px !important;
}
</style>
