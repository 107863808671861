<template>
  <el-row>
    <el-col :span="18" class="dis-sta-cen">
      <v-for v-for="(item, index) in valueArr" :key="index">
        <el-button class="elButtonShop" type="text" @click="buttonListClick(item)"><p :class="selectId === item.id ? 'selected' : 'noSelected'" class="hover-class">{{ item.key }}</p></el-button>
      </v-for>
      <el-popover
        placement="bottom"
        :width="354"
        v-model:visible="visible">
        <el-row>
          <el-col :span="9">
            <div class="grid-content bg-purple">
              <el-input :maxlength="8" @input="minMoneyHand = minMoneyHand.match(/\d+(\.\d{0,4})?/) ? minMoneyHand.match(/\d+(\.\d{0,4})?/)[0] : ''" v-model="minMoneyHand" size="mini" placeholder="最低金额">
                <template #suffix>
                  <i style="margin-right: 10px;font-size: 14px;color: #666666;">万</i>
                </template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="1"><div style="text-align:center;height: 100%;line-height:100%;">-</div></el-col>
          <el-col :span="9">
            <div class="grid-content bg-purple">
              <el-input :maxlength="8" @input="maxMoneyHand = maxMoneyHand.match(/\d+(\.\d{0,4})?/) ? maxMoneyHand.match(/\d+(\.\d{0,4})?/)[0] : ''" v-model="maxMoneyHand" size="mini" placeholder="最高金额">
                <template #suffix>
                  <i style="margin-right: 10px;font-size: 14px;color: #666666;">万</i>
                </template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="4" :offset="1"><div><el-button size="mini" @click="confirmButtonClick">确认</el-button></div></el-col>
        </el-row>
        <template #reference>
          <el-button class="elButtonCustom" type="text" @click="customButtonClick(item)"><p :class="referenceClass">{{ customItem !== '' ? customItem.key : '自定义' }}</p></el-button>
        </template>
      </el-popover>
    </el-col>
  </el-row>
</template>

<script>
// 注册资金
import { ElMessage } from 'element-plus'
export default {
  name: 'registered-capital',
  data () {
    return {
      // thisDefaultShowNum: 0
      selectId: 0,
      customItem: '',
      valueArr: [],
      visible: false,
      minMoneyHand: null,
      maxMoneyHand: null,
      referenceClass: {
        customSelected: this.visible,
        customNoSelected: !this.visible,
        selected: false,
        noSelected: true
      }
    }
  },
  mounted () {
    const vm = this
    vm.initValueArr()
  },
  methods: {
    initValueArr () {
      const vm = this
      vm.valueArr.push({
        id: 1,
        key: '100万以下',
        startValue: null,
        endValue: 1000000
      })
      vm.valueArr.push({
        id: 2,
        key: '100万 - 500万',
        startValue: 1000000,
        endValue: 5000000
      })
      vm.valueArr.push({
        id: 3,
        key: '500万 - 1000万',
        startValue: 5000000,
        endValue: 10000000
      })
      vm.valueArr.push({
        id: 4,
        key: '1000万 - 2000万',
        startValue: 10000000,
        endValue: 20000000
      })
      vm.valueArr.push({
        id: 5,
        key: '2000万以上',
        startValue: 20000000,
        endValue: null
      })
    },
    // 按钮列表的点击
    buttonListClick (item) {
      const vm = this
      // vm.customItem = ''
      vm.referenceClass.selected = false
      vm.referenceClass.noSelected = true
      if (vm.selectId === item.id) {
        vm.selectId = ''
        this.$emit('handleChange', {
          id: '',
          key: '',
          startValue: '',
          endValue: ''
        }) // 触发父组件中handleChange事件并传参Jack
      } else {
        vm.selectId = item.id
        this.$emit('handleChange', item) // 触发父组件中handleChange事件并传参Jack
      }
    },
    // 点击了自定义
    customButtonClick () {
      const vm = this
      vm.visible = true
      vm.minMoneyHand = null
      vm.maxMoneyHand = null
    },
    // 点击了确认
    confirmButtonClick () {
      const vm = this
      if (vm.minMoneyHand === null ||
        vm.minMoneyHand === undefined ||
        vm.minMoneyHand === '') {
        return ElMessage.warning({
          message: '请填写最低金额',
          type: 'warning'
        })
      }
      if (vm.maxMoneyHand === null ||
        vm.maxMoneyHand === undefined ||
        vm.maxMoneyHand === '') {
        return ElMessage.warning({
          message: '请填写最高金额',
          type: 'warning'
        })
      }
      vm.minMoneyHand = vm.formatNum(vm.minMoneyHand)
      vm.maxMoneyHand = vm.formatNum(vm.maxMoneyHand)
      if (vm.minMoneyHand < vm.maxMoneyHand) { // 符合要求
        vm.visible = false
        vm.selectId = 6
        const item = {
          id: 6,
          key: vm.minMoneyHand + '万 - ' + vm.maxMoneyHand + '万',
          startValue: vm.minMoneyHand * 10000,
          endValue: vm.maxMoneyHand * 10000
        }
        vm.referenceClass.selected = true
        vm.referenceClass.noSelected = false
        vm.customItem = item
        this.$emit('handleChange', item) // 触发父组件中handleChange事件并传参Jack
      } else {
        return ElMessage.warning({
          message: '最低金额要小于最高金额',
          type: 'warning'
        })
      }
    },
    closeSelect () {
      const vm = this
      vm.selectId = ''
      vm.customItem = ''
      vm.referenceClass.selected = false
      vm.referenceClass.noSelected = true
    },
    // 如果数字最后一位是点就去掉
    formatNum (num) {
      if (num === null || num === undefined) {
        return num
      }
      let thisNum = num.toString()
      console.log(thisNum)
      while (thisNum.substring(thisNum.length - 1, thisNum.length) === '.') {
        thisNum = thisNum.substring(0, thisNum.length - 1)
      }
      return thisNum * 1
    }
  }
}
</script>

<style scoped>
.elButtonCustom{
  margin-right: 30px;
}
.elButtonShop{
  margin-right: 30px;
}
.elButtonShop p {
  font-size: 14px;
  /*color: #666666;*/
}
.customSelected {
  font-size: 14px;
  color: #5D6FE9;
}
.customNoSelected {
  font-size: 14px;
  color: #666666;
}
.selected{
  color: #5D6FE9;
}
.noSelected{
  color: #666666;
}
.hover-class:hover {
  color: rgba(93, 111, 233, 1);
}
</style>
